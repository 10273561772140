<template>
  <!-- 运营外部班级 -->
  <div class="pageContol listWrap outClassList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">培训管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a"
          >班级显示设置</a
          >
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items: flex-start">
          <div class="searchbox" style="margin-bottom: 8px">
            <div title="班级编码" class="searchboxItem ci-full">
              <span class="itemLabel">班级编码:</span>
              <el-input
                  v-model="projectCode"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入班级编码"
              />
            </div>
            <div title="班级名称" class="searchboxItem ci-full">
              <span class="itemLabel">班级名称:</span>
              <el-input
                  v-model="projectName"
                  type="text"
                  clearable
                  size="small"
                  placeholder="请输入班级名称"
              />
            </div>
            <div title="机构名称" class="searchboxItem ci-full">
              <span class="itemLabel">机构名称:</span>
              <el-select
                  size="small"
                  v-model="compId"
                  remote
                  :remote-method="getCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
              >
                <el-option
                    v-for="item in CompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                ></el-option>
              </el-select>
            </div>
            <div title="课程方" class="searchboxItem ci-full">
              <span class="itemLabel">课程方:</span>
              <el-select
                  size="small"
                  v-model="providerId"
                  remote
                  :remote-method="getCompanyList"
                  filterable
                  clearable
                  placeholder="请至少输入两个字搜索"
              >
                <el-option
                    v-for="item in CompanyList"
                    :key="item.compId"
                    :label="item.compName"
                    :value="item.compId"
                ></el-option>
              </el-select>
            </div>
          </div>
          <div class="searchbox" style="margin-bottom: 8px">
            <div title="是否纳入核算" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width: 7rem">是否纳入核算:</span>
              <el-select
                  size="small"
                  v-model="isAccounting"
                  clearable
                  placeholder="请选择是否纳入核算"
              >
                <el-option
                  v-for="item in isAccountingData"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
            <div class="df">
              <el-button type="primary" class="bgc-bv" round @click="getData()"
              >查询</el-button
              >
            </div>
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
                ref="multipleTable"
                :data="tableData"
                height="100%"
                size="small"
                tooltip-effect="dark"
                style="width: 100%"
                :header-cell-style="tableHeader"
                stripe
            >
              <el-table-column
                  label="序号"
                  align="center"
                  type="index"
                  :index="indexMethod"
                  fixed
                  width="100px"
              />
              <el-table-column
                  label="班级编码"
                  align="left"
                  prop="projectCode"
                  show-overflow-tooltip
                  min-width="180"
                  fixed
              />
              <el-table-column
                  label="班级名称"
                  align="left"
                  prop="projectName"
                  show-overflow-tooltip
                  min-width="180"
              />

              <el-table-column
                  label="机构名称"
                  align="left"
                  show-overflow-tooltip
                  prop="compName"
                  min-width="220"
              />
              <el-table-column
                  label="课程名称"
                  align="left"
                  show-overflow-tooltip
                  prop="courseName"
                  min-width="180"
              />
              <el-table-column
                  label="视频总时长"
                  align="left"
                  show-overflow-tooltip
                  prop="totalDurationStr"
                  min-width="110"
              />
              <el-table-column
                  label="课程方数量"
                  align="right"
                  show-overflow-tooltip
                  prop="providerNum"
                  min-width="110"
              />

              <el-table-column label="操作" align="center" width="160px" fixed="right">
                <div slot-scope="scope">
                  <el-button
                      style="padding: 0 5px"
                      type="text"
                      size="mini"
                      @click="handleSet(scope.row)"
                  >设置</el-button
                  >
                  <el-button
                      style="padding: 0 5px"
                      type="text"
                      size="mini"
                      @click="seeLogs(scope.row)"
                  >查看日志</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
            :apiData="apiData"
            @sizeChange="sizeChange"
            @getData="getData"
        />
      </div>
      <el-dialog
          title="设置核算"
          :visible.sync="dialogVisible"
          top="4%"
          width="60%"
          :center="true"
          :before-close="doClose"
          :close-on-click-modal="false"
      >
        <div>
          <el-row style="margin-left: 20px;line-height: 34px">
            <el-col :span="12">
              班级编码：{{ info.projectCode }}
            </el-col>
            <el-col :span="12">
              班级名称：{{ info.projectName }}
            </el-col>
            <el-col :span="12">
              课程名称：{{ info.courseName }}
            </el-col>
          </el-row>
          <br/>
          <h3>课程方</h3>
          <br/>
          <el-table
              ref="multipleTable"
              :data="tableData2"
              height="100%"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
          >
            <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                width="80px"
            />
            <el-table-column
                label="课程方名称"
                align="center"
                prop="providerName"
                show-overflow-tooltip
                min-width="120"
            />
            <el-table-column
                label="课件数量"
                align="center"
                prop="kpointNum"
                show-overflow-tooltip
                min-width="100"
            />
            <el-table-column
                label="公共课数量"
                align="center"
                prop="publicKpointNum"
                show-overflow-tooltip
                min-width="100"
            />
            <el-table-column
                label="是否纳入核算"
                align="left"
                min-width="100"
            >
              <template slot-scope="{ row }">
                <el-switch
                    v-model="row.isAccounting"
                    active-color="#13ce66"
                    inactive-color="#ff4949"
                    :active-value="true"
                    :inactive-value="false">
                </el-switch>
                <span :style="{'color':row.isAccounting?'#13ce66':'#ff4949','margin-left': '10px'}">{{row.isAccounting?'纳入核算':'不纳入核算'}}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <span slot="footer" class="dialog-footer">
          <div class="btnBox">
            <el-button @click="doClose">取消</el-button>
            <el-button class="bgc-bv" @click="doOk">确认</el-button>
          </div>
        </span>
      </el-dialog>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
import { resetKeepAlive } from "@/utils/common";
import { mapGetters } from "vuex";
export default {
  name: "classDisplaySetList",
  components: {
    Empty,
    PageNum,
  },
  mixins: [List],
  data() {
    return {
      projectName: "",
      outplant: "",
      deletedstate: "true",
      userstate: "",
      projectCode: "",
      compId: "",
      providerId:"", // 课程方id 
      isAccounting:"", // 是否纳入核算

      CompanyList: [],
      courseUserList:[], // 课程方数据
      isAccountingData:[{
        value: '',
        label: '全部'
      },{
        value: true,
        label: '是'
      },{
        value: false,
        label: '否'
      }],

      info: {
        projectCode: "",
        projectName: "",
        courseName: "",
      },

      dialogVisible: false,
      tableData2: [{}],
    };
  },
  computed: {
    ...mapGetters({
      downloadItems: "getDownloadItems",
    }),
  },
  created() {

  },
  methods: {
    // // 时间切换
    // getTime(val) {
    //   if (!val) {
    //     return "0秒";
    //   }
    //   let h = parseInt(val / 60 / 60);
    //   let m = parseInt((val % 3600) / 60);
    //   let s = val % 60;
    //   if (h < 9) {
    //     h = "0" + h;
    //   }
    //   if (m < 9) {
    //     m = "0" + m;
    //   }
    //   if (s < 9) {
    //     s = "0" + s;
    //   }
    //   return (
    //       (h != "00" ? h + "时" : "") +
    //       (m != "00" ? m + "分" : "") +
    //       (s != "00" ? s + "秒" : "") || ""
    //   );
    // },

    // 机构名称
    getCompanyList(query) {
      if (query.trim().length >= 2) {
        this.$post("/sys/company/queryCompanyList", { compName: query })
            .then((res) => {
              if (res.status == 0) {
                this.CompanyList = res.data || [];
              }
            })
            .catch(() => {
              return;
            });
      } else {
        this.CompanyList = [];
      }
    },
    // 课程方
    searchCourseUser(query) {
      if (query.trim().length > 0) {
        this.$post(
          "/biz/ec/course/owner/list",
          { name: query },
          3000,
          true,
          2
        ).then((res) => {
          this.courseUserList = res.data;
        });
      } else {
        this.courseUserList = [];
      }
    },
    handleSet(row){
      this.$post("/run/project/accountingInfo", { projectCourseId: row.projectCourseId })
          .then((res) => {
            if (res.status == 0) {
              // console.log(res.data)
              this.info =  {
                ...row
              }
              this.tableData2 = res.data
              this.tableData2.forEach((e,i)=>{
                e.projectId = row.projectId
                e.projectCode = row.projectCode
                e.courseName = row.courseName
              })
              this.dialogVisible = true
            }
          })
          .catch(() => {
            return;
          });
    },
    doClose() {
      this.dialogVisible = false;
    },
    doOk(){
      this.$post("/run/project/setAccounting", this.tableData2 )
          .then((res) => {
            if (res.status == 0) {
              this.$message({
                message: "设置成功",
                type: "success",
              });
              this.getData(-1);
              this.dialogVisible = false

            }
          })
          .catch(() => {
            return;
          });
    },
    seeLogs(row){
      this.$router.push({
        path: "/web/classDisplaySetLogs",
        query: {
          projectId: row.projectId
        }
      });
    },
    // 获取列表数据
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.compId) {
        params.compId = this.compId;
      }
      if (this.projectName) {
        params.projectName = this.projectName;
      }
      if (this.providerId) {
        params.providerId = this.providerId;
      }
      if (this.projectCode) {
        params.projectCode = this.projectCode;
      }
      if (typeof this.isAccounting != 'string') {
        params.isAccounting = this.isAccounting;
      }
      this.doFetch({
        url: "/run/project/projectDisplayList",
        params,
        pageNum,
      });
    },
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        }
      },
      // 深度观察监听
      deep: true,
    },
  },
  beforeRouteLeave: resetKeepAlive,
};
</script>
<style lang="less">
.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
<style lang="less" scoped>
.searchboxfl {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start !important;
}
///deep/.el-switch__label--left.is-active + .el-switch__core{
//  border-color: rgb(255, 73, 73)!important;
//  background-color: rgb(255, 73, 73)!important;
//}
///deep/.el-switch.is-checked .el-switch__core {
//  background-color: #5fb878 !important;
//  border-color: #5fb878 !important;
//}
///deep/ .el-switch__label--left{
//  left: 0!important;
//  z-index: 999!important;
//}
///deep/ .el-switch__label--right{
//  right: 0!important;
//  z-index: 999!important;
//}
///deep/ .el-switch__label{
//  color: #999!important;
//}
///deep/ .el-switch__label.el-switch__label--left.is-active {
//  color: #ff4949!important;
//}
///deep/ .el-switch__label.el-switch__label--right.is-active {
//  color: #13ce66!important;
//}
.addlist {
  h3 {
    padding: 10px 0;
  }
}
.zsBtn {
  margin-left: 20px;
  button {
    height: 40px;
  }
}
.certificate-box {
  > div:last-child {
    border: 0;
  }
}
.card-border {
  padding: 10px;
  border-bottom: 1px dashed #eee;
}
</style>